
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Content } from '../Templates'
import { Modal, Space, Select, Button, notification } from 'antd'
import { Icon } from 'react-onsenui';
import { Transition } from '../App'

import CountUp from 'react-countup'
import { format } from 'timeago.js'
import anime from 'animejs'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import Title from '../components/Title';
import BigText from './../components/BigText';
import Paragraph from './../components/Paragraph';
import SmallText from './../components/SmallText';
import { useTranslation, Trans } from "react-i18next";


export function BigSwitch({ onChange, checked }) {
    const [value, setValue] = useState(checked)
    useEffect(() => {
        setValue(value)
    }, [checked])
    function trigChange() {
        setValue(!value)
        if (onChange) onChange(!value)
    }
    return (<label className="switch">
        <input type="checkbox" checked={value} onChange={trigChange} />
        <span className="slider round"></span>
    </label>)
}


export function Dashboard({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState(null)

    const [lastContact, setLastContact] = useState({ date: 0 })
    const navigate = useNavigate()

    const [requestedMode, setRequestedMode] = useState(null)
    const [requestedList, setRequestedList] = useState(null)
    const [modalMode, setModeMode] = useState(false)

    const [iosMessage, setIosMessage] = useState(false)

    // this is for updating contact information
    useEffect(() => {
        if (!kernel.api.token)
            return (navigate("/install/welcome"))

        Transition(['#page'], null, "inLeft")

        // special handle for iOS
        async function ios() {
            if (kernel.device.iosApp === true) {
                if (await kernel.device.ios.settingsIsActivated() !== true)
                    setIosMessage(true)
                else
                    setIosMessage(false)
            }
        }

        // special handle for Android
        async function android() {
            if (kernel.device.androidApp === true) {
                if (await kernel.device.android.settingsIsActivated() !== true)
                    setIosMessage(true)
                else
                    setIosMessage(false)
            }
        }

        var stop = false
        var timer = null
        async function checkLastContact() {
            timer = null

            // exec iOS dependance
            await ios()

            // exec Android dependance
            await android()

            if (kernel.api.lastContact > 0) {
                const diff = (kernel.api.lastContact - Date.now())
                setLastContact({ date: kernel.api.lastContact, diff })
            }
            if (kernel.api.policy !== mode)
                setMode(kernel.api.policy)

            if (stop !== true)
                timer = setTimeout(checkLastContact, 2500)
        }
        timer = setTimeout(checkLastContact)
        return (() => {
            stop = true
            if (timer) clearTimeout(timer)
        })
    }, [loaded])

    // this is for handling stats
    const [stats, setStats] = useState({
        week: {
            all: 0,
            passed: 0,
            blocking: 0,
            detecting: 0
        }
    })

    useEffect(() => {
        // Prevent back button from appearing
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
        };
        if (!kernel.api.token)
            return (navigate("/install/welcome"))

        var stop = false
        var timer = null
        async function updateStats() {
            const ret = await kernel.api.getStats('total30d')
            if (!ret || !ret.data?.values)
                return (timer = setTimeout(updateStats, 10000))

            const newStats = { ...stats }
            const lIdx = ret.data.values.length - 1
            const curWeek = ret.data.values[lIdx]
            const prevWeek = ret.data.values[lIdx - 1]

            if (curWeek) {
                newStats.week.all = curWeek[1]
                newStats.week.blocking = curWeek[2]
                newStats.week.blockingPer = newStats.week.blocking * 100 / newStats.week.all
                newStats.week.bypassing = curWeek[3]
                newStats.week.detecting = curWeek[4]
                newStats.week.passed = curWeek[5]
            }

            if (stats.week.blocking !== newStats.week.blocking)
                setStats(newStats)

            if (stop !== true)
                timer = setTimeout(updateStats, 30000)
        }
        timer = setTimeout(updateStats)
        return (() => {
            stop = true
            if (timer) clearTimeout(timer)
        })
    }, [loaded])

    function openModalMode() {
        setRequestedMode(mode)
        setModeMode(true)
    }

    async function processMode() {
        if (requestedMode === mode && requestedList === null)
            return (setModeMode(false))

        const update = {
            mode: requestedMode
        }
        if (requestedList)
            update.list = requestedList

        setLoading(true)
        const ret = await kernel.api.post('/dohzel/device/change', update)
        if (ret.error) {
            notification.warning({
                message: ret.error,
                placement: 'bottomRight'
            })
            return
        }

        await kernel.api.beat(true)
        setLoaded(!loaded)
        setLoading(false)
        setModeMode(false)
    }

    async function downloadMobileconfig() {
        const ret = await kernel.api.stream('/dohzel/device/mobileconfig')
        if (!ret) {
            notification.warning({
                message: t("Dashboard.errorGettingFile"),
                placement: 'bottomRight',
                description: ret.error,
            })
        }
        else {
            const url = window.URL.createObjectURL(ret);
            window.location.href = url
            window.URL.revokeObjectURL(url);
        }
    }

    const { t, i18n, ready } = useTranslation();
    
    // if (ready) {
        return (
            <Content>
                <Modal
                    open={modalMode}
                    maskStyle={{ backgroundColor: "rgba(0, 45, 68, 0.5)" }}
                    centered
                    closeIcon={null}
                    footer={[
                        <Button key="back" onClick={() => setModeMode(false)}>
                            {t("Dashboard.cancelButton")}
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={processMode}>
                            {t("Dashboard.changeButton")}
                        </Button>
                    ]}
                >
                    {/* <h3>Policy</h3>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "0.9em" }}>All detected threats are blocked</td>
                                <td align='right' width="30%">
                                    <Button
                                        style={{ width: "100%" }}
                                        type={requestedMode === "blocking" ? "primary" : null}
                                        onClick={() => setRequestedMode("blocking")}
                                    >
                                        ✅ Blocking Mode
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "0.9em" }}>All detected threats without blocking</td>
                                <td align='right' width="30%">
                                    <Button
                                        style={{ width: "100%" }}
                                        type={requestedMode === "detecting" ? "primary" : null}
                                        onClick={() => setRequestedMode("detecting")}
                                    >
                                        🕵️‍♂️ Detecting Only
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "0.9em" }}>The filters are disabled</td>
                                <td align='right' width="30%">
                                    <Button
                                        style={{ width: "100%" }}
                                        type={requestedMode === "bypassing" ? "primary" : null}
                                        onClick={() => setRequestedMode("bypassing")}
                                    >
                                        ⚠️ Bypassing
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div style={{ paddingTop: 20 }}>
                        <h3>Threat Network</h3>
                        <Select
                            defaultValue={kernel.api.list}
                            style={{
                                defaultBg: "#ffffff",
                                defaultColor: "#ffffff",
                                width: "100%",
                            }}
                            onChange={(key) => setRequestedList(key)}
                            options={kernel.api?.context?.lists.map((item) => {
                                return ({
                                    value: item.id,
                                    label: item.name
                                })
                            })}
                        />
                    </div> */}

                    {/* {kernel?.device?.browser?.device?.brand === "Apple" && kernel.device.iosApp !== true ?
                        <div style={{ paddingTop: 20 }}>
                            <h3>{t("Dashboard.profileTitle")}</h3>
                            <Button onClick={downloadMobileconfig} style={{}}>
                                {t("Dashboard.downloadButton")}
                            </Button>
                        </div>
                        : 
                    null}

                    {kernel.device.browser.device && kernel.device.androidApp === true  ?
                        <div style={{ paddingTop: 20 }}>
                            <h3>{t("Dashboard.profileTitle")}</h3>
                            <CopyToClipboard text={kernel.api.dot}
                                onCopy={() => {
                                    notification.success({
                                        placement: 'bottomRight',
                                        message: t("Dashboard.successCopied"),
                                    })
                                }}>
                                <Button style={{}}>
                                    <CopyOutlined /> {t("Dashboard.copyDotConfig")}
                                </Button>
                            </CopyToClipboard>
                        </div>
                        : 
                    null} */}

                </Modal>

                <div id="page" className='container' style={{ textAlign: "center" }}>
                    <div style={{ paddingBottom: 5 }}>
                        <div style={{
                            fontSize: "3.5em",
                            fontWeight: 400,
                            marginBottom:20
                        }}>
                            {iosMessage !== true && mode === "blocking" ?
                                <>
                                    <div style={{ fontSize: "1.2em", marginBottom:20 }}>✅</div>
                                    <BigText centered><Trans i18nKey="Dashboard.blockingTitle"></Trans></BigText>
                                </>
                                : null}
                            {iosMessage !== true && mode === "detecting" ?
                                <>
                                    <div style={{ fontSize: "1.2em", marginBottom:20 }}>🕵️‍♂️</div>
                                    <BigText centered><Trans i18nKey="Dashboard.detectingTitle"></Trans></BigText>
                                </>
                                : null}
                            {iosMessage !== true && mode === "bypassing" ?
                                <>
                                    <div style={{ fontSize: "1.2em", marginBottom:20 }}>⚠️</div>
                                    <BigText centered><Trans i18nKey="Dashboard.bypassingTitle"></Trans></BigText>
                                </>
                                : null}
                            {iosMessage === true ?
                                <>
                                    <div style={{ fontSize: "1.2em" }}>🎯</div><strong>{t('Dashboard.almostReady')}</strong>
                                </>
                                : null}
                        </div>
                        <SmallText centered>
                            {iosMessage !== true ?
                                <>
                                    {lastContact.diff < ~(60 * 60 * 1000) ?
                                        <>
                                            {t('Dashboard.noTraffic')}
                                        </>
                                        :
                                        <>
                                            {t('Dashboard.lastRequest')} <strong>{format(lastContact.date)}</strong>
                                        </>}
                                </>
                                :
                                <>
                                    {t("Dashboard.deviceNotActivated")}
                                </>
                            }

                        </SmallText>
                    </div>

                    {iosMessage !== true && stats.week.blocking > 0 ?
                        <div style={{ margin:'40px 0' }}>

                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td style={{
                                            width: "50%",
                                            fontWeight: 500,
                                            paddingRight: 10,
                                            textAlign: "right",
                                        }}>
                                            {t("Dashboard.blockedThreat")}<br />
                                            <small>{t("Dashboard.blockedThreatLast30d")}</small>
                                        </td>
                                        <td style={{
                                            width: "50%",
                                            fontSize: "2em",
                                            paddingLeft: 10,
                                            borderLeft: "3px solid #FF4C4C",
                                            fontWeight: 800,
                                            textAlign: "left"
                                        }}>
                                            <CountUp end={stats.week.blocking} />
                                            <div style={{
                                                fontStyle: "italic",
                                                fontSize: "0.25em",
                                                fontWeight: 400,
                                            }}>
                                                {t("Dashboard.orText")} <CountUp end={stats.week.blockingPer} />{t("Dashboard.percentAllChecks")}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* <div>
                                <Gauge {...config} style={{
                                    height: 100,
                                    width: 100,
                                    margin: "0 auto",
                                    fontSize: "0.9em"
                                }} />
                            </div> */}

                        </div>
                        : null}

                    <div style={{ fontWeight: 100, /* paddingTop: stats.week.blocking > 0 ? 10 : 30  */}}>
                        <Button.Group >



                            {iosMessage === true ?
                                <Button onClick={() => Transition(['#page'], () => navigate("/explain"), "outLeft")} style={{
                                    fontWeight: 400,
                                    color: "#001E2D",
                                    backgroundColor: "#CCD3D7",
                                    textTransform: "uppercase"
                                }}>
                                    {t("Dashboard.explainMe")}
                                </Button>
                                :
                                <>
                                    {
                                        kernel.api.deviceManagement != 'mdm' &&
                                        <Button onClick={() => Transition(['#page'], () => navigate("/settings"), "outLeft")} style={{
                                            fontWeight: 400,
                                            color: "#001E2D",
                                            backgroundColor: "#CCD3D7",
                                            textTransform: "uppercase"
                                        }}>
                                            {t("Dashboard.settings")}
                                        </Button>
                                    }
                                    {stats.week.blocking > 0 ?
                                        <>
                                            < Button onClick={() => Transition(['#page'], () => navigate("/stats"), "outLeft")} style={{
                                                fontWeight: 400,
                                                color: "#E5F2F8",
                                                backgroundColor: "#005985",
                                                textTransform: "uppercase"
                                            }}>
                                                {t("Dashboard.stats")}
                                            </Button>

                                        </>
                                    : null}
                                    < Button onClick={() => Transition(['#page'], () => navigate("/audit"), "outLeft")} style={{
                                        fontWeight: 400,
                                        color: "#E5F2F8",
                                        backgroundColor: "#005985",
                                        textTransform: "uppercase"
                                    }}>
                                        {t("Dashboard.audit")}
                                    </Button>

                                </>
                            }

                        </Button.Group>
                    </div>

                    <div style={{ margin: '20px 0' }}>
                        {/* <div style={{ fontSize: "0.6em", fontWeight: 500, textTransform: "uppercase", paddingBottom:5 }}>❯ {t("Dashboard.currentNetwork")} ❮</div>
                        <div style={{ fontSize: "0.8em", fontWeight: 600, textTransform: "uppercase" }}>{kernel.api?.listInfo?.name}</div> */}
                        {/* <div style={{ fontSize: "0.6em", fontWeight: 500, textTransform: "uppercase" }}>Containing <CountUp end={kernel.api?.listInfo?.stats?.total} /> known threats</div> */}
                        {/* <div style={{fontSize: "0.5em", fontWeight: 400, textTransform: "uppercase"}}>Change network by clicking on Settings</div> */}
                    </div>
                </div>
            </Content >
        );
    // }
    // return <span>{t('Loading')}</span>;
}