import { Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Transition, kernel } from '../App';

import { Icon, Page, Toolbar, ToolbarButton } from 'react-onsenui';
import { UpgradeToPro } from './UpgradeToPro';
import { useTranslation } from "react-i18next";

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

const CSSlogo = {
    fontWeight: 800,
    color: "#B7CED9"
}

export function UpgradeToProButton({ onClick }) {

    return (<Tag
        className="tag-pro"
        onClick={onClick}
        style={{
            cursor: "pointer",
            fontSize: 8,
            border: 0,
            padding: 0,
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: 700,
            textTransform: "uppercase"
        }}>
        upgrade to PRO
    </Tag>)
}

export function Content({
    back, 
    backTransition,
    onEdit,
    onSave,
    right,
    style,
    topAlign,
    nofooter,
    children
}) {
    const [loaded, setLoaded] = useState(true)
    const [top, setTop] = useState(null)
    const [upgrading, setUpgrading] = useState(false)
    const [logged, setLogged] = useState(false)
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const [isAuthOpened, setIsAuthOpened] = useState(false)
    const [isSignupOpened, setIsSignupOpened] = useState(false)
    const [isForgotPasswordOpened, setIsForgotPasswordOpened] = useState(false)

    const { t, i18n, ready } = useTranslation();

    const items = [
        {
            label: '🇬🇧 En',
            key: 'en',
            onClick: () => { 
                setLng('🇬🇧 En');
                i18n.changeLanguage('en'); 
            }
        },
        {
            label: '🇫🇷 Fr',
            key: 'fr',
            onClick: () => { 
                setLng('🇫🇷 Fr');
                i18n.changeLanguage('fr'); 
            }
        },
        {
            label: '🇩🇪 De',
            key: 'de',
            onClick: () => { 
                setLng('🇩🇪 De');
                i18n.changeLanguage('de'); 
            }
        },
        {
            label: '🇪🇦 Es',
            key: 'es',
            onClick: () => { 
                setLng('🇪🇦 Es');
                i18n.changeLanguage('es'); 
            }
        },
        {
            label: '🇮🇹 It',
            key: 'it',
            onClick: () => { 
                setLng('🇮🇹 It');
                i18n.changeLanguage('it'); 
            }
        }
    ];

    const [lng, setLng] = useState(items?.find(item => item.key == i18n.language)?.label)

    const navigate = useNavigate();

    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const content = useRef()

    useEffect(() => {

        function check() {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
            if (!content.current) return
            const usable = window.innerHeight
            const diff = usable - content.current.offsetHeight
            if (diff > 0)
                setTop(diff / 2)
            else
                setTop(40)

            setTimeout(check, 5000)
        }


        if (topAlign === true) {
            setTop(40)
        }
        else {
            setTimeout(check, 100)
        }

    }, [content.current])

    useEffect(() => {
        const token = localStorage.getItem('session.current')
        token ? setLogged(true) : setLogged(false);
    }, [])

    const handleMenu = () => {
        setIsMenuOpened(true)
    }
  
    if (ready) {
        return (
            <>
                {upgrading === true ? <UpgradeToPro onClose={() => setUpgrading(false)} /> : null}

                <div>
                    <Page
                        style={{ height: 44, background: '#003048 !important' }}
                        renderToolbar={() =>
                            <Toolbar style={{background: "#003048"}} modifier="cover-content">
                                <div className="left">
                                    {back ?
                                        <div style={{ cursor: "pointer", display:'flex' }} onClick={() => {
                                            if (!backTransition)
                                                navigate(back === 'PREVIOUS' ? -1 : back)
                                            else {
                                                Transition(['#page'], () => navigate(back === 'PREVIOUS' ? -1 : back), backTransition)
                                            }
                                        }}>
                                                <Icon size={{ default: 30, material: 35 }} style={{ color: "#FFFFFF", marginLeft: 5, position: 'relative'/* , top: 3.5 */, marginRight:10 }} icon="fa-caret-left" />
                                                <span style={{color:'#FFF'}}>{t('BackButton')}</span>
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="center">
                                    <div style={{ ...CSSlogo }}>
                                        DOHZEL <span style={{ fontSize: "0.4em" }}>AV</span>
                                        {kernel.api.license === "active" ?
                                            <span className="tag-pro" style={{ position: "absolute", fontSize: "0.5em", marginLeft: "5px", top: 10 }}>
                                                PRO
                                            </span>
                                            :
                                            null
                                            // <span className="tag-beta" style={{ position: "absolute", fontSize: "0.5em", marginLeft: "5px", top: 10 }}>
                                            //     BETA
                                            // </span>
                                        }
                                    </div>
                                </div>
                                <div className="right">
                                {
                                    (onEdit || onSave) &&
                                    <ToolbarButton style={{color: '#FFFFFF', fontSize: 20}}>
                                        {
                                            kernel.api.token ? 
                                                <span onClick={onEdit}>
                                                    <Icon size={{default: 25, material: 30}} onClick={handleMenu} style={{color: "#FFFFFF", marginRight: 5}} icon="md-edit" />Edit
                                                </span>
                                            : 
                                                <span>
                                                    <Icon size={{default: 25, material: 30}} onClick={handleMenu} style={{color: "#FFFFFF", marginRight: 5}} icon="md-check" />Save
                                                </span>
                                        }
                                    </ToolbarButton>
                                }
                                {/* <ToolbarButton style={{color: '#FFFFFF', fontSize: 20}}>
                                    {right ? right : kernel.api.license !== "active" ? <UpgradeToProButton
                                        onClick={() => setUpgrading(true)}
                                    /> : null}
                                    {
                                        logged ?
                                            <Icon size={{default: 30, material: 35}} onClick={handleMenu} style={{color: "#FFFFFF", marginRight: 5}} icon="md-menu" />
                                        :
                                            <Icon size={{default: 30, material: 35}} onClick={handlSingin} style={{color: "#FFFFFF", marginRight: 5}} icon="fa-user" />
                                    }
                                </ToolbarButton> */}

                                    {/* <ToolbarButton style={{color: '#FFFFFF', fontSize: 20}}>
                                        <Icon size={{default: 30, material: 35}} onClick={() => changeLanguage("fr")} style={{color: "#FFFFFF", marginRight: 5}} icon="md-translate" />
                                        
                                    </ToolbarButton> */}
                                    <div style={{marginRight: 20}}>
                                        <Dropdown style={{color: '#FFFFFF', fontSize: 20, marginRight: 50}} menu={{ items }} trigger={['click']}>
                                            <a onClick={(e) => e.preventDefault()}>
                                                <Space>
                                                    {(lng)}
                                                    <DownOutlined />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Toolbar> 
                        }
                    />
                </div>

                <div className='mainContainer' style={style} ref={content}>
                    {children}
                </div>

                {
                    !nofooter &&
                    <div className='footer' style={{
                        zIndex: 11,
                        position: "fixed",
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        textAlign: "center",
                        //background:'red'
                    }}>
                        <div style={{
                            color: "#E5F2F8",
                            paddingTop: 20,
                            paddingBottom: 10,
                            fontWeight: 300,
                            fontSize: "0.6em",
                            textTransform: "uppercase",
                        }}>
                            <strong>DOHZEL</strong> {kernel.info.iosApp === true ? ' for iOS' : null} &copy; Hafnova {(new Date()).getFullYear()}
                        </div>
                    </div>
                }
            </>
        );
    }
    return <span>{t('Loading')}</span>;
}