import { useNavigate } from "react-router-dom";
import { Content } from '../../Templates';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Logo from '../../components/Logo';
import Paragraph from './../../components/Paragraph';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { notification } from 'antd';
import { Input } from 'react-onsenui';

export function Welcome({ kernel }) {

    const navigate = useNavigate()
    const { t, i18n, ready } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [coupon, setCoupon] = useState("");

    // useEffect(() => {
    //     kernel.device.android.settingsSetDoH();
    // }, []);

    useEffect(() => {
        var stop = false
        var timer = null
        const mdmCheck = () => {
            timer = null;

            const installProcess = JSON.parse(localStorage.getItem('installProcess') || '{}');
            if (installProcess?.isMDMdevice) {
                navigate("/install/device");
            }

            timer = setTimeout(mdmCheck, 1000)
        }
        timer = setTimeout(mdmCheck)
        return (() => {
            stop = true
            if (timer) clearTimeout(timer)
        })
    }, []);

    async function verifyCoupon() {

        // verify the reseller coupon
        if (coupon && coupon.length > 0) {
            const ret = await kernel.api.get('/dohzel/device/coupon?code=' + coupon)
            if (ret.error) {
                notification.warning({
                    message: t('Welcome.coupon'),
                    placement: 'bottomRight',
                    description: ret.error,
                })
                return
            }

            // save to install process
            const installProcess = JSON.parse(localStorage.getItem('installProcess') || '{}');
            installProcess.reseller = coupon
            localStorage.setItem('installProcess', JSON.stringify(installProcess))
        }

        navigate('/install/enroll')
    }

    return (
        <Content backTransition="outRight" topAlign={true}>
            <div style={styles.container} class='container'>
                {ready ? <>
                    <Logo />
                    <div style={styles.content}>
                        <Title centered>{t('Welcome.title')}</Title>
                        <div style={styles.paragraphContainer}>
                            <Paragraph centered>{t('Welcome.content')}</Paragraph>
                        </div>
                        {/* <div style={{ ...styles.paragraphContainer, margin: "0 auto", width: 300 }}>
                            <Input
                                autoFocus
                                style={{color: '#FFFFFF',fontSize: 50, display:'block'}}
                                onChange={(e) => setCoupon(e.target.value)}
                                placeholder={t('Welcome.coupon')}
                            />
                        </div> */}
                    </div>
                    <div><Button onClick={verifyCoupon}>{t('Welcome.button')}</Button></div>
                </>
                    :
                    <span>{t('Loading')}</span>}
            </div>
        </Content>
    )
}

const styles = {
    container: {
        padding: '0 15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    centerContent: {
        position: 'relative',
    },
    content: {
        fontSize: 30,
        marginBottom: 20
    },
    paragraphContainer: {
        marginTop: 20,
        // marginBottom:20
    },
    input: {
        border: 0,
        padding: 5,
        fontSize: "0.5em",
        color: "black"
    }
}