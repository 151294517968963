import logo from './logo.svg'

import 'antd/dist/reset.css'
import './App.css'
import { useEffect, useState } from 'react'

import {
    HashRouter as Router,
    Route,
    redirect,
    Routes,
    Navigate
} from "react-router-dom"

// Webpack CSS import
import 'onsenui/css/onsenui.css';
// import 'onsenui/css/onsen-css-components.css';
import './onsen-css-components.css';

import { ConfigProvider } from 'antd'
import anime from 'animejs'

import * as Sentry from "@sentry/react"

import { Kernel } from './Kernel'
import * as Pages from './Pages'

import Tag from './Pages/Settings/Tag';
import Connexion from './Pages/Settings/Connexion';

// localStorage.removeItem("deviceToken")
// localStorage.removeItem("dohToken")
// localStorage.removeItem("installProcess")


export var Config = {}

if (process.env.REACT_APP_ENV === "development") {
    Config = {
        // central: "http://localhost:10000/api",
        central: "https://api.dev.hafnova.com/api",
        // central: "https://api.diventry.com/api",
        joinToken: "kY43V4k2PhTR5rTkrmvs",
        dot: "secure.dev.hafnova.com/",
        // central: "http://localhost:5689/api",
        ui: "http://localhost:3001/#",
        // sentry: {
        //     dsn: "https://94235c6a784a4968b9cce94bac1e2659@o4504621191790592.ingest.sentry.io/4504621265846272",
        //     tracesSampleRate: 1.0
        // }
    }
}
else if (process.env.REACT_APP_ENV === "preproduction") {
    Config = {
        central: "https://api.dev.hafnova.com/api",
        joinToken: "kY43V4k2PhTR5rTkrmvs",
        dot: "secure.dev.hafnova.com",
        ui: "https://secure.dev.hafnova.com",
        sentry: {
            dsn: "https://4a71d139b37059ad368459f3825f426b@o4507396908318720.ingest.de.sentry.io/4507397241110608",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/secure\.dev\.hafnova\.com/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        }
    }
}
else if (process.env.REACT_APP_ENV === "production") {
    Config = {
        central: "https://api.hafnova.com/api",
        joinToken: "G6HsFMYZAsZi8rsv6vTD",
        dot: "secure.hafnova.com",
        ui: "https://secure.hafnova.com",
        sentry: {
            dsn: "https://a3c8d6f063d0653b3bf03a554ca79417@o4507396908318720.ingest.de.sentry.io/4507396929028176",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/secure\.hafnova\.com/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        }
    }
}
else {
    console.warning("No configuration found")
}

// start sentry
if (Config.sentry)
    Sentry.init(Config.sentry)

export const kernel = new Kernel(Config)

export const Transition = (targets, onFinish, effect = "toRight", duration = 125) => {

    window.document.body.style.overflow = 'hidden'
    function end() {
        if (onFinish) onFinish()
        window.document.body.style.overflow = 'auto'
    }

    if (effect === "inRight") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            // opacity: [0, 1],
            translateX: [window.innerWidth / 2, 0],
            complete: end
        })
    }
    else if (effect === "outRight") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            // opacity: [0, 1],
            translateX: [0, window.innerWidth],
            complete: end
        })
    }
    else if (effect === "inLeft") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            // opacity: [0, 1],
            translateX: [~window.innerWidth, 0],
            complete: end
        })
    }
    else if (effect === "outLeft") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            // opacity: [0, 1],
            translateX: [0, ~window.innerWidth],
            complete: end
        })
    }
    else if (effect === "inFade") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            opacity: [0, 1],
            complete: end
        })
    }
    else if (effect === "outFade") {
        anime({
            targets,
            easing: 'cubicBezier(.19, 1, 0.22, 1)',
            duration,
            opacity: [0, 1],
            complete: end
        })
    }
}

function Popover({ }) {

    const topBanner = 40
    const padding = 15
    const radius = 15
    const inPadding = padding

    const [visible, setVisible] = useState(true)
    const [content, setContent] = useState(null)

    kernel.popover = {
        lastOpen: false,
        requestOpen: true,
        ...kernel.popover,
        open: () => {
            if (kernel.popover.lastOpen === true)
                return
            setVisible(true)
            anime({
                targets: ['#popover'],
                easing: 'cubicBezier(.19, 1, 0.22, 1)',
                duration: 100,
                translateY: [window.innerHeight, 0],
                complete: () => {
                    kernel.popover.lastOpen = true
                }
            })
        },
        close: () => {
            if (kernel.popover.lastOpen !== true)
                return

            anime({
                targets: ['#popover'],
                easing: 'cubicBezier(.19, 1, 0.22, 1)',
                duration: 100,
                translateY: [0, window.innerHeight],
                complete: () => {
                    kernel.popover.lastOpen = false
                    setVisible(false)
                }
            })
        },
        content: (data) => {
            setContent(data)
        }
    }

    return (
        <div id="popover" style={{
            display: visible === true ? "block" : "none",
            zIndex: 20,
            position: "fixed",
            left: padding,
            top: topBanner,
            height: window.innerHeight - topBanner,
            width: window.innerWidth - padding * 2,
            // borderRadius: `${radius}px ${radius}px 0px 0px`,
            borderRadius: radius,
            // backgroundColor: "red",
            // backgroundColor: "rgba(0, 45, 68, 1)",
            textAlign: "center",
            padding: inPadding,
            background: "linear-gradient(0deg, #E2E2E2, #F0F0F0 )"
        }}>
            {content}
        </div>
    )
}


function App() {
    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState("Running kernel")
    const [fatal, setFatal] = useState(null)
    const [isMDMdevice, setIsMDMdevice] = useState(false);


    async function runKernel() {
        if (kernel.loaded === true) return

        const rebound = kernel.url.searchParams.get("rebound")
        if (rebound && rebound.length > 4) {
            localStorage.setItem("deviceToken", rebound)
            kernel.url.searchParams.delete("rebound")
            return (window.location.href = kernel.url.toString())
        }

        kernel.on("log", setLoading)
        kernel.fatal = setFatal
        await kernel.init()
        kernel.remove("log", setLoading)
        setTimeout(() => {
            setLoading(null)

        }, 1000)
    }

    useEffect(() => {
        async function processBlur() {
            kernel.blured = true
        }
        async function processFocus() {
            if (kernel.blured !== true) return
            // setLoading("Checking updates")
            await kernel.api.checkVersion()

            // setLoading("Updating informations")
            await kernel.api.beat()
            // setTimeout(() => {
            //     setLoading(null)
            // }, 1000)
            delete kernel.blured
        }
        window.addEventListener("focus", processFocus)
        window.addEventListener("blur", processBlur)
        return (() => {
            window.removeEventListener("focus", processFocus)
            window.removeEventListener("blur", processBlur)
        })
    }, [loaded]);

    const handleMDMprocess = async () => {
        if (kernel?.device?.androidApp) {
            const restrictions = await kernel.device.android.settingsGetRestrictions();
            if (restrictions?.PROFILE_CODE) {
                const tags = await getActiveTags();
                const valideCode = await enrollDevice(restrictions?.PROFILE_CODE, restrictions?.NAME ?? '');
                const mdmDefaultSettings = {
                    code: restrictions?.PROFILE_CODE,
                    codeType: "free",
                    logToProfile: restrictions?.LOG_POLICY ?? "all",
                    name: restrictions?.NAME ?? '',
                    noCode: false,
                    isMDMdevice: true,
                    tags
                }
                if (valideCode) {
                    localStorage.setItem('installProcess', JSON.stringify(mdmDefaultSettings));
                    setIsMDMdevice(true);
                }
            }
        }
    }

    const enrollDevice = async (code, name) => {
        const res = await kernel.api.post('/dohzel/profile/enroll', {code})
        if (!res || res.error) {
            return false;
        }
        await kernel.api.post(`/dohzel/device/change`, {name});
        return res.data.type != 'unknown';
    }

    const getActiveTags = async () => {
        const response = await kernel.api.get('/dohzel/tags')
        if (response.error) {
            return [];
        }
        return response.data?.filter((tag) => tag.default)?.map(tag => tag?.name);
    }

    async function splashLoaded() {
        await runKernel();
        handleMDMprocess();
    }

    const gotoRightPath = () => {
        const installProcess = JSON.parse(localStorage.getItem('installProcess') || '{}');
        if (!installProcess?.codeType) {
            return '/install/welcome'
        }
        else if (installProcess.logToProfile) {
            return '/install/device'
        }
        else if (installProcess.security) {
            return '/install/logs'
        }
        else if (installProcess?.codeType == 'mdm') {
            return '/install/device'
        }
        else if (installProcess?.codeType == 'free') {
            return '/install/tag'
        }
        else {
            return '/install/welcome'
        }
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Tag: {
                        defaultBg: "#CCCBCC",
                        defaultColor: "#000C13",
                    },
                    Button: {
                        fontWeight: 500,
                        colorPrimaryHover: "#E5E6E7",
                    },
                    Select: {
                        optionPadding: 5,
                        optionSelectedFontWeight: 500,
                        colorBorder: "#E5E6E7",
                        colorPrimaryHover: "#005985"
                        // selectorBg: "#005985",
                        // optionSelectedBg: "#E5E6E7",
                        // optionSelectedColor: "#E5E6E7"
                    }
                },
                token: {
                    fontFamily: 'Montserrat',
                    primaryColor: "#000C13",
                    // colorInfo: "#000C13",
                    defaultColor: "#CCCBCC",
                    // colorBgBase: "#134667",
                    sizeStep: 3,
                    sizeUnit: 3,
                    borderRadius: 4,
                },
            }}
        >
            <Router>
                {/* <Popover /> */}



                {fatal ?
                    <Pages.Fatal kernel={kernel} activate={true}>
                        {fatal}
                    </Pages.Fatal>
                    :
                    <>
                        {loading ?
                            <Pages.Splash kernel={kernel} onFinish={splashLoaded}>{loading}</Pages.Splash>
                            :
                            <Routes>
                                <Route path="/dashboard" element={<Pages.Dashboard kernel={kernel} />} />
                                <Route path="/stats" element={<Pages.Stats kernel={kernel} />} />
                                <Route path="/audit" element={<Pages.Audit kernel={kernel} />} />
                                <Route path="/settings" element={<Pages.Settings kernel={kernel} />} />
                                <Route path="/settings/connexion" element={<Connexion kernel={kernel} />} />
                                <Route path="/list" element={<Pages.ListManagement kernel={kernel} />} />
                                <Route path="/tags" element={<Tag kernel={kernel} />} />
                                <Route path="/info" element={<Pages.Info kernel={kernel} />} />
                                <Route path="/privacy" element={<Pages.Privacy kernel={kernel} />} />
                                <Route path="/session/select" element={<Pages.Session.Select kernel={kernel} />} />
                                <Route path="/session/login" element={<Pages.Session.Login kernel={kernel} />} />
                                <Route path="/session/signup" element={<Pages.Session.Signup kernel={kernel} />} />
                                <Route path="/session/forgot-password" element={<Pages.Session.ForgotPassword kernel={kernel} />} />
                                <Route path="/explain" element={<Pages.Explain kernel={kernel} />} />
                                <Route path="/install/welcome" element={<Pages.Install.Welcome kernel={kernel} />} />
                                <Route path="/install/enroll" element={<Pages.Install.Enroll kernel={kernel} />} />
                                <Route path="/install/tag" element={<Pages.Install.Tag kernel={kernel} />} />
                                <Route path="/install/logs" element={<Pages.Install.Logs kernel={kernel} />} />
                                <Route path="/install/enrollCode" element={<Pages.Install.EnrollCode kernel={kernel} />} />
                                <Route path="/install/invalidCode" element={<Pages.Install.InvalidCode kernel={kernel} />} />
                                <Route path="/install/mdm" element={<Pages.Install.MDM kernel={kernel} />} />
                                <Route path="/install/free" element={<Pages.Install.Free kernel={kernel} />} />
                                <Route path="/install/device" element={<Pages.Install.Device kernel={kernel} />} />

                                <Route
                                    path="*"
                                    element={<Navigate to={isMDMdevice ? "/install/device" : (kernel.api.token ? "/dashboard" : gotoRightPath())} replace />}
                                />
                            </Routes>
                        }
                    </>
                }

            </Router>
        </ConfigProvider>
    );
}

export default App;
